import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { fetcher } from '@weezevent/weezjs-core';
import { User, Context } from '../../../models';
import { WeezHeader, Button, Icon, AnchorNav, SplitContainer, ToastContext } from '@weezevent/nacre';
import { usePermissions } from '../../../models/user/permissions';
import { UserSideBlock, UserInformations, UserRoles, UserHistory } from './UserBlocks';
import RoleCreationModal from './roles/RoleCreationModal';
import { useLocationSegment } from '../../../hooks';
import SecretModal from './AppSecretModal';
import NewUser from './NewUser';
import { AppCredentials } from './AppCredentials';

export const UserProfile = ({ organizationId, userId }) => {
    const { t } = useTranslation();
    const toast = React.useContext(ToastContext);
    const history = useHistory();
    const [pathSegment, pathSegment_singular, location] = useLocationSegment(organizationId);
    const permissions = usePermissions();
    const [addRoleModal, setAddRoleModal] = React.useState(false);
    const { openSecretModal, client_id, client_secret } = location.state || {};
    const [openSecret, setOpenSecret] = React.useState(() => openSecretModal);
    const [newUserModal, setNewUserModal] = React.useState(false);
    const [user, loadingUser, syncUser] = User.useApiModel(
        {
            id: userId,
            organizationId,
            pathSegment,
            launch: userId,
            cache: false,
            allow_cache: false
        },
        [userId, organizationId, pathSegment]
    );

    const userName = React.useMemo(() => {
        if (loadingUser || !user) {
            return ' ';
        }

        if (!user.first_name && !user.last_name && !user.application_name) {
            return t(`sauron.${pathSegment_singular}.anonymous`);
        }
        return `${user.resolvedName}`;
    }, [user, loadingUser]);

    const anchorItems = React.useMemo(() => {
        const items = [
            {
                id: 'information',
                title: t(`sauron.${pathSegment_singular}.information`)
            },
            pathSegment === 'applications' ? { id: 'credentials', title: t('sauron.application.credentials') } : null,
            {
                id: 'roles',
                title: t('common.roles')
            },
            {
                id: 'history',
                title: t('common.history')
            }
        ];

        return items.filter(Boolean);
    }, [pathSegment]);

    const handleDeleteUser = React.useCallback(() => {
        const url = `/ticket/users/${user.username}`;
        fetcher
            .delete(url, {
                data: {
                    user: {
                        username: userId
                    }
                }
            })
            .then(response => {
                if (response.status === 204) {
                    toast.success(t('sauron.toasts.roles.delete-role-success'));
                }
            })
            .catch(() => {
                toast.error(t('sauron.toasts.error'));
            })
            .finally(() => {
                syncUser();
            });
    }, [userId, syncUser]);

    const handleDeleteRole = React.useCallback(
        _role => {
            const { context, role } = _role;
            const url = `/ticket/roles/${role.slug}/users/`;
            fetcher
                .delete(url, {
                    data: {
                        context: new Context(context).values,
                        user: {
                            username: userId
                        }
                    }
                })
                .then(response => {
                    if (response.status === 204) {
                        toast.success(t('sauron.toasts.roles.delete-role-success'));
                    }
                })
                .catch(() => {
                    toast.error(t('sauron.toasts.error'));
                })
                .finally(() => {
                    syncUser();
                });
        },
        [userId, syncUser]
    );

    return (
        <>
            <WeezHeader
                rightComponent={[
                    <div key={0}>
                        <Button
                            inverted={true}
                            onClick={handleDeleteUser}
                            disabled={!permissions.isAdmin()}
                            icon={<Icon circular={false} className={null} color={null} inverted={false} name="trash" />}
                            label={t('common.cta.delete')}
                        />
                    </div>
                ]}
                title={userName}
                backLink={{
                    onClick: () => {
                        history.push(organizationId ? `/organizations/O${organizationId}/${pathSegment}` : `/${pathSegment}`);
                    }
                }}
            />
            <AnchorNav rootId="main" items={anchorItems}>
                {openSecret && <SecretModal open={openSecretModal} onClose={() => setOpenSecret(false)} clientId={client_id} clientSecret={client_secret}></SecretModal>}
                {newUserModal && (
                    <NewUser
                        organizationId={organizationId}
                        pathSegmentItems={[pathSegment, pathSegment_singular]}
                        open={newUserModal}
                        onClose={() => setNewUserModal(false)}
                        user={user}
                        sync={syncUser}
                        edit={true}
                    />
                )}

                {addRoleModal && <RoleCreationModal open={addRoleModal} organizationId={organizationId} user={user} onClose={() => setAddRoleModal(false)} sync={syncUser} />}
                <SplitContainer displayAdditional={false} additional={<UserSideBlock loading={loadingUser} user={user} />}>
                    <UserInformations loading={loadingUser} user={user} name={userName} setOpenModal={setNewUserModal} pathSegment={pathSegment} />
                    {pathSegment === 'applications' && <AppCredentials loading={loadingUser} user={user} />}
                    <UserRoles loading={loadingUser} user={user} onToggle={() => setAddRoleModal(true)} handleDelete={handleDeleteRole} />
                    <UserHistory loading={loadingUser} user={user} />
                </SplitContainer>
            </AnchorNav>
        </>
    );
};
