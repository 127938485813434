import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'i18next';

import { Numbers, LoaderCard } from '@weezevent/nacre';

import { getCount } from '../utils/utils';

import css from './totalCount.module.css';

export default function TotalCount({ pageQuery, countQuery, pageQueryLoading, countQueryLoading, setCount }) {
    const locale = i18n.language;

    const { count, showSymbol } = React.useMemo(() => {
        const totalCount = getCount(pageQuery, 'total-Count') || getCount(countQuery, 'total-Count');
        const minCount = getCount(pageQuery, 'min-count');

        const count = totalCount && !pageQueryLoading && !countQueryLoading ? totalCount : minCount;
        const showSymbol = !pageQueryLoading && !totalCount;

        return { count, showSymbol };
    }, [pageQuery, countQuery, pageQueryLoading, countQueryLoading]);

    React.useEffect(() => {
        if (setCount) {
            setCount(count);
        }
    }, [count]);

    if (pageQueryLoading) {
        return <LoaderCard className={css['loader-count-loader']} active />;
    }

    return (
        <span>
            {<Numbers value={count} locale={locale} />}
            {showSymbol && '+'}
        </span>
    );
}

TotalCount.propTypes = {
    pageQuery: PropTypes.array,
    pageQueryLoading: PropTypes.bool,
    countQuery: PropTypes.array,
    countQueryLoading: PropTypes.bool,
    setCount: PropTypes.func
};

TotalCount.defaultProps = {
    pageQuery: [],
    countQuery: [],
    pageQueryLoading: false,
    countQueryLoading: false,
    setCount: () => {}
};
