import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { FrameCard, Message } from '@weezevent/nacre';
import { ClientDataField } from './AppSecretModal';
import { LoadingCard } from '../../../components';

import css from './user.module.css';

export const AppCredentials = ({ user, loading }) => {
    const { t } = useTranslation();
    return (
        <FrameCard id="credentials" title={t('sauron.application.credentials')}>
            <LoadingCard loading={loading} height="20vh">
                <ClientDataField clientTitle={t('sauron.application.message.client_id')} clientData={user?.username?.slice(4)} />
                <div className={css['application-security-message']}>
                    <Message title={t('sauron.application.message.security-title')} type={'default'}>
                        {t('sauron.application.message.security-message')}
                    </Message>
                </div>
            </LoadingCard>
        </FrameCard>
    );
};

AppCredentials.propTypes = {
    user: PropTypes.object
};

AppCredentials.defaultProps = {
    user: {}
};
