import React from 'react';

import { useTranslation } from 'react-i18next';

import { Modal, CopyToClipboardLink, Message, Button } from '@weezevent/nacre';

import css from './user.module.css';

const SecretModal = ({ open, onClose, clientId, clientSecret }) => {
    const { t } = useTranslation();

    return (
        <Modal
            size={'large'}
            open={open}
            allowExternalClosing={false}
            onClose={onClose}
            title={t(`sauron.application.creation-modal-title`)}
            customFooter={<Button primary onClick={onClose} label={t('sauron.application.message.confirm')} />}
        >
            <div className={css['application-block']}>
                <div className={css['application-message']}>
                    <Message title={t('sauron.application.message.title')} type={'default'}>
                        {t('sauron.application.message.text')}
                    </Message>
                </div>
                <ClientDataField clientTitle={t('sauron.application.message.client_id')} clientData={clientId} />
                <ClientDataField clientTitle={t('sauron.application.message.client_secret')} clientData={clientSecret} />
            </div>
        </Modal>
    );
};

export const ClientDataField = ({ clientTitle, clientData }) => {
    return (
        <>
            <div> {clientTitle} </div>
            <pre className={css['application-data']}>
                <div className={css['application-client-block']}>
                    <div>{clientData}</div>

                    <span className={css['application-cc-icon-color']}>
                        <CopyToClipboardLink delay={1000} value={clientData} copyOnIconClick />
                    </span>
                </div>
            </pre>
        </>
    );
};
export default SecretModal;
