import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dropdown, DropdownItem, DropdownSort, FrameCard, Icon, Table, TableCellDropdown, ToastContext } from '@weezevent/nacre';

import { useDateFormat } from '../../../hooks';
import { usePermissions } from '../../../models/user/permissions';

import { ActionModel, Webhook, WebhookCalls } from '../../../models';

import { TotalCount, InfoTextTooltip, LoadingCard, PaginationDropDown, Paginator, QuickFilters, SauronEmptyCard } from '../../../components';
import WebhookTriggerModal from './WebHookTriggerModal';

import { capitalizeFirstLetter } from '../../../utils/utils';

import css from './webhook.module.css';

export const WebHookCalls = ({ webhook, organizationId, setOpenModal, syncWebhook }) => {
    const { t } = useTranslation();
    const user = usePermissions();
    const toast = React.useContext(ToastContext);
    const [queryParams, setQueryParams] = React.useState({ retried: false, limit: 50, offset: 0, disable_count: true });
    const [currentPage, setCurrentPage] = React.useState(0);
    const [disableRetry, setDisableRetry] = React.useState(false);
    const [openTriggerModal, setOpenTriggerModal] = React.useState(false);
    const [paginationCount, setPaginationCount] = React.useState(0);

    const [callsCount, loadingCallsCount] = WebhookCalls.useApiModel(
        {
            webhookId: webhook?.pk,
            organizationId,
            cache: false,
            allow_cache: false,
            query: { limit: 0, offset: 0 },
            launch: Boolean(webhook)
        },
        [webhook, organizationId, webhook?.pk]
    );

    const [executedCalls, loadingExecutedCalls, syncExecutedCalls] = WebhookCalls.useApiModel(
        {
            webhookId: webhook?.pk,
            organizationId,
            cache: false,
            allow_cache: false,
            query: queryParams,
            launch: Boolean(webhook)
        },
        [webhook, organizationId, queryParams]
    );

    const handlePage = React.useCallback(
        value => {
            setCurrentPage(value);
            setQueryParams({ ...queryParams, offset: queryParams.limit * value });
        },
        [queryParams]
    );

    const handleQuickFilter = React.useCallback(
        (key, value, checked) => {
            let params = { ...queryParams };

            if (key === 'all' || !checked) {
                delete params['success'];
                return setQueryParams({ ...params });
            }

            setQueryParams({ ...params, [key]: value });
        },
        [queryParams]
    );

    const isRetryDisabled = React.useMemo(() => {
        if (!webhook?.can_be_retried || loadingExecutedCalls) {
            return true;
        }
        return disableRetry || !executedCalls?.length;
    }, [webhook, executedCalls, loadingExecutedCalls, disableRetry]);

    const retryTooltip = React.useMemo(() => {
        if (!webhook || loadingExecutedCalls || !executedCalls?.length || webhook?.can_be_retried) {
            return '';
        }

        const { last_retry_failed_calls: last_retry } = webhook;
        const { properTime, properDate } = useDateFormat({ date: last_retry });
        const lastRetryDate = last_retry ? `${properDate} - ${properTime}` : null;
        const translationKey = `sauron.webhook.${last_retry ? 'trigger_tooltip_date' : 'trigger_tooltip'}`;

        return <Trans i18nKey={translationKey} values={{ date: lastRetryDate }} />;
    }, [webhook, loadingExecutedCalls, executedCalls]);

    const handleRetry = React.useCallback(() => {
        const action = 'retry_failed_calls';
        Webhook.executeBulkAction(
            {
                type: action,
                organizationId,
                id: webhook?.pk
            },
            Webhook
        ).then(response => {
            let values = { name: webhook?.name };
            setDisableRetry(true);
            toast.success(t(`sauron.webhook.actions.${action}.progress`, values));

            ActionModel.pollBulkAction(response)
                .then(rsp => {
                    if (rsp.status === 'success') {
                        values.count = rsp.summary.success;
                        toast.success(t(`sauron.webhook.actions.${action}.success`, { ...values }));
                    }
                })
                .catch(() => {
                    toast.error(t(`sauron.webhook.actions.${action}.error`, values));
                })
                .finally(() => {
                    setDisableRetry(false);
                    syncWebhook();
                });
        });
    }, [organizationId, webhook, syncWebhook]);

    return (
        <FrameCard
            id="webhook_logs"
            title={
                <div className={css['webhook-logs-trigger']}>
                    <div className={css['webhook-logs-block']}>
                        <span className={css['webhook-logs']}>{t('common.logs')}</span>
                        (
                        <TotalCount
                            pageQuery={executedCalls}
                            countQuery={callsCount}
                            pageQueryLoading={loadingExecutedCalls}
                            countQueryLoading={loadingCallsCount}
                            setCount={setPaginationCount}
                        />
                        )
                    </div>
                    {user.canEditWebhooks() && (
                        <InfoTextTooltip className={css['webhook-tooltip']} title={retryTooltip} tooltip={Boolean(retryTooltip)}>
                            <Button basic label={t('sauron.webhook.trigger')} icon={<Icon name="sort" />} onClick={() => setOpenTriggerModal(true)} disabled={isRetryDisabled} />
                        </InfoTextTooltip>
                    )}
                </div>
            }
            subtitle={t(`sauron.webhook.logs.subtitle`)}
        >
            <LoadingCard loading={loadingExecutedCalls}>
                <WebhookTriggerModal open={openTriggerModal} onClose={() => setOpenTriggerModal(false)} onAccept={handleRetry} />
                <PaginationDropDown
                    label={'sauron.webhook.logs.page_size'}
                    displaySelect={false}
                    count={paginationCount}
                    limit={queryParams.limit}
                    pageSizes={[50, 100, 250]}
                    handlePage={handlePage}
                    onChange={value => {
                        setQueryParams({ ...queryParams, limit: value, offset: 0 });
                        setCurrentPage(0);
                    }}
                />
                <QuickFilters>
                    <QuickFilters.Item
                        label={t('sauron.webhook.notifications.quick-filters.all')}
                        active={!('success' in queryParams)}
                        onToggle={checked => handleQuickFilter('all', null, checked)}
                    />
                    <QuickFilters.Item
                        label={t('sauron.webhook.notifications.quick-filters.failed')}
                        active={queryParams.success === false}
                        onToggle={checked => handleQuickFilter('success', false, checked)}
                    />
                    <QuickFilters.Item
                        label={t('sauron.webhook.notifications.quick-filters.success')}
                        active={queryParams.success === true}
                        onToggle={checked => handleQuickFilter('success', true, checked)}
                    />
                </QuickFilters>
                <Table useNew>
                    <Table.THead>
                        <Table.Tr isHeader>
                            <th className={css['webhooks-table-medium']}>
                                <DropdownSort
                                    label={t('common.id')}
                                    name="id"
                                    active={['id', `-id`].includes(queryParams.ordering)}
                                    ordering={queryParams.ordering}
                                    size="mini"
                                    items={[
                                        <DropdownItem.Icons
                                            key={1}
                                            checked={queryParams.ordering === 'id'}
                                            item={t('common.ordering.ascending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: 'id' })}
                                        />,
                                        <DropdownItem.Icons
                                            key={2}
                                            checked={queryParams.ordering === '-id'}
                                            item={t('common.ordering.descending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: '-id' })}
                                        />
                                    ]}
                                />
                            </th>
                            <th className={css['webhooks-table-medium']}>
                                <DropdownSort
                                    label={t('common.created')}
                                    name="created"
                                    active={['created', `-created`].includes(queryParams.ordering)}
                                    ordering={queryParams.ordering}
                                    size="mini"
                                    items={[
                                        <DropdownItem.Icons
                                            key={1}
                                            checked={queryParams.ordering === 'created'}
                                            item={t('common.ordering.ascending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: 'created' })}
                                        />,
                                        <DropdownItem.Icons
                                            key={2}
                                            checked={queryParams.ordering === '-created'}
                                            item={t('common.ordering.descending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: '-created' })}
                                        />
                                    ]}
                                />
                            </th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.type`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.status`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`common.actions`)}</th>
                        </Table.Tr>
                    </Table.THead>
                    <Table.TBody>
                        {!loadingExecutedCalls &&
                            executedCalls?.length > 0 &&
                            executedCalls?.map(call => <CallItem key={call.pk} call={call} webhook={webhook} setOpenModal={setOpenModal} sync={syncExecutedCalls} />)}
                    </Table.TBody>
                </Table>
                {!loadingExecutedCalls &&
                    (executedCalls?.length > 0 ? (
                        <Paginator currentPage={currentPage} onPageChange={handlePage} pageSize={queryParams.limit} totalCount={paginationCount} />
                    ) : (
                        <SauronEmptyCard title={t('sauron.webhook.logs.empty-card-title')} subTitle={t('sauron.webhook.logs.empty-card-subtitle')} />
                    ))}
            </LoadingCard>
        </FrameCard>
    );
};

const CallItem = ({ webhook, call, setOpenModal, sync }) => {
    const { t } = useTranslation();
    const user = usePermissions();
    const toast = React.useContext(ToastContext);

    const statusCode = React.useMemo(() => {
        const rule = new RegExp(/HTTP\/\d\.\d (\d{3})/);
        const responseCode = call.response.match(rule)?.[1];
        return responseCode;
    }, [call]);

    const status = React.useMemo(() => {
        if (!statusCode) {
            return capitalizeFirstLetter(call.result);
        }
        return `${statusCode} - ${capitalizeFirstLetter(call.result)}`;
    }, [statusCode, call]);

    const created = React.useMemo(() => {
        const { properTime, properDate } = useDateFormat({ date: call.created });
        return `${properDate} - ${properTime}`;
    }, [call]);

    const handleRetry = React.useCallback(() => {
        call.retry({ webhookId: webhook.pk })
            .catch(() => toast.error(t('sauron.toasts.error')))
            .finally(() => sync());
    }, [call, sync]);

    return (
        <Table.Tr>
            <Table.Td>{call.pk}</Table.Td>
            <Table.Td>{created}</Table.Td>
            <Table.Td>{call?.type ? t(`sauron.webhooks.filters.types.${call?.type}`) : '-'}</Table.Td>
            <Table.Td>{status}</Table.Td>
            <TableCellDropdown>
                {user.canEditWebhooks() && <Dropdown.Item item={t('sauron.webhook.logs.actions.trigger')} onClick={handleRetry} disabled={statusCode === '200' || call.retried} />}
                <Dropdown.Item item={t('sauron.webhook.logs.actions.payload')} onClick={() => setOpenModal({ open: true, call })} />
            </TableCellDropdown>
        </Table.Tr>
    );
};
