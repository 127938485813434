import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, Initials } from '@weezevent/nacre';

import { TicketCard, LoadingCard } from '../../../components';

import css from './user.module.css';
import { usePermissions } from '../../../models/user/permissions';

export const UserInformations = ({ user, name, loading, setOpenModal, pathSegment }) => {
    const { t } = useTranslation();
    const permissions = usePermissions();
    return (
        <TicketCard id="information">
            <LoadingCard loading={loading} height="20vh">
                <TicketCard.Header>
                    <div className={css['user-informations']}>
                        <div className={css['user-identity']}>
                            <Initials content={name} size="big" />
                            <span className={css['user-fullname']}>{name}</span>
                        </div>
                        {permissions.isAdmin() && (
                            <div className={css['user-edit']} onClick={() => setOpenModal(true)}>
                                <Icon name="edit" />
                                {t('common.cta.edit')}
                            </div>
                        )}
                    </div>
                    <div className={css['user-details']}>
                        <UserDetails label={t('common.email')} value={user?.email} />
                        {pathSegment === 'applications' && (
                            <>
                                <UserDetails label={t('common.first-name')} value={user?.first_name} />
                                <UserDetails label={t('common.last-name')} value={user?.last_name} />
                            </>
                        )}
                    </div>
                </TicketCard.Header>
                <TicketCard.Footer />
            </LoadingCard>
        </TicketCard>
    );
};

UserInformations.propTypes = {
    user: PropTypes.object,
    name: PropTypes.string,
    loading: PropTypes.bool
};

UserInformations.defaultProps = {
    name: '-',
    loading: true,
    user: {}
};

const UserDetails = ({ label, value }) => {
    return (
        <span className={css['user-detail']}>
            <span className={css['user-detail-label']}>{label}</span>
            <span className={css['user-detail-content']}>{value || '-'}</span>
        </span>
    );
};

UserDetails.propTypes = {
    user: PropTypes.object,
    field: PropTypes.string
};

UserDetails.defaultProps = {
    user: {},
    field: ''
};
